import ReciboVenda from "../pages/Vendas/components/Recibo.vue";
import ReciboEntrada from "../pages/Entradas/components/Recibo.vue";
import PrintReport from "../pages/Relatorios/components/PrintReport.vue";
import ReciboMovimento from "../pages/Caixa/components/Recibo.vue";
// import PrestacaoContasMes from "../pages/Relatorios/components/PrestacaoContasMes";
// import OrdemManutencao from "../pages/Manutencoes/components/OrdemManutencao.vue";

const routes = [
  { path: "/", redirect: { path: "/home" } },
  {
    path: "/",
    component: () => import("layouts/AuthLayout.vue"),
    name: "auth-layout",
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("pages/Login/Index.vue"),
      },
      {
        path: "/reset/:email?",
        name: "reset",
        props: true,
        component: () => import("pages/Login/Reset.vue"),
      },
      {
        path: "/password",
        name: "password",
        props: true,
        component: () => import("pages/AlterarSenha/Index.vue"),
      },
      {
        path: "/:id?/catalogo/",
        name: "catalogo",
        props: true,
        component: () => import("src/pages/Catalogo/Index.vue"),
      },
      {
        path: "/:id?/carrinho",
        name: "carrinho",
        props: true,
        meta: { title: "Carrinho" },
        component: () => import("src/pages/Catalogo/components/Pagamento.vue"),
      },
      {
        path: "/conta",
        name: "conta",
        props: true,
        component: () => import("pages/Conta/Index.vue"),
      },
      {
        path: "/ajuda",
        name: "ajuda",
        props: true,
        component: () => import("pages/Ajuda/Index.vue"),
      },
      {
        path: "/downloads",
        name: "downloads",
        props: true,
        component: () => import("pages/Download/Index.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    name: "main-layout",
    children: [
      {
        path: "/home",
        name: "home",
        meta: { title: "Home" },
        component: () => import("pages/Index.vue"),
      },
      {
        path: "clientes",
        name: "clientes",
        meta: { title: "Clientes" },
        component: () => import("pages/Clientes/Index.vue"),
      },
      {
        path: "/cliente/:id?",
        name: "cliente",
        meta: { title: "Cliente" },
        props: true,
        component: () => import("pages/Clientes/Cliente.vue"),
      },
      {
        path: "/produtos",
        name: "produtos",
        meta: { title: "Produtos" },
        component: () => import("pages/Produtos/Index.vue"),
      },
      {
        path: "/produto/:id?",
        name: "produto",
        props: true,
        meta: { title: "Produto" },
        component: () => import("src/pages/Produtos/Produto.vue"),
      },
      {
        path: "/vendas",
        name: "vendas",
        meta: { title: "Vendas" },
        component: () => import("pages/Vendas/Index.vue"),
      },
      {
        path: "/venda/:id?",
        name: "venda",
        props: true,
        meta: { title: "Venda" },
        component: () => import("src/pages/Vendas/Venda.vue"),
      },
      {
        path: "/pagamento",
        name: "pagamento",
        props: true,
        meta: { title: "Pagamento" },
        component: () => import("src/pages/Vendas/components/Pagamento.vue"),
      },
      {
        path: "/fornecedores",
        name: "fornecedores",
        meta: { title: "Fornecedores" },
        component: () => import("pages/Fornecedores/Index.vue"),
      },
      {
        path: "/fornecedor/:id?",
        name: "fornecedor",
        props: true,
        meta: { title: "Fornecedor" },
        component: () => import("pages/Fornecedores/Fornecedor.vue"),
      },
      {
        path: "/categorias",
        name: "categorias",
        meta: { title: "Categorias" },
        component: () => import("pages/Categorias/Index.vue"),
      },
      {
        path: "/categoria/:id?",
        name: "categoria",
        props: true,
        meta: { title: "Categoria" },
        component: () => import("pages/Categorias/Categoria.vue"),
      },
      {
        path: "/relatorios",
        name: "relatorios",
        meta: { title: "Relatórios" },
        component: () => import("pages/Relatorios/Index.vue"),
      },
      {
        path: "/despesas",
        name: "despesas",
        meta: { title: "Despesas" },
        component: () => import("pages/Despesas/Index.vue"),
      },
      {
        path: "/despesa/:id?",
        name: "despesa",
        props: true,
        meta: { title: "Despesa" },
        component: () => import("pages/Despesas/Despesa.vue"),
      },
      {
        path: "/receitas",
        name: "receitas",
        meta: { title: "Receitas" },
        component: () => import("pages/Receitas/Index.vue"),
      },
      {
        path: "/receita/:id?",
        name: "receita",
        props: true,
        meta: { title: "Receita" },
        component: () => import("pages/Receitas/Receita.vue"),
      },
      {
        path: "/manutencoes",
        name: "manutencoes",
        meta: { title: "Manutenções" },
        component: () => import("pages/Manutencoes/Index.vue"),
      },
      {
        path: "/manutencao/:id?",
        name: "manutencao",
        props: true,
        meta: { title: "Manutenção" },
        component: () => import("pages/Manutencoes/Manutencao.vue"),
      },
      {
        path: "/entradas",
        name: "entradas",
        meta: { title: "Entradas" },
        component: () => import("pages/Entradas/Index.vue"),
      },
      {
        path: "/entrada/:id?",
        name: "entrada",
        props: true,
        meta: { title: "Entrada" },
        component: () => import("src/pages/Entradas/Entrada.vue"),
      },
      {
        path: "/saidas",
        name: "saidas",
        meta: { title: "Saídas" },
        component: () => import("pages/Saidas/Index.vue"),
      },
      {
        path: "/saida/:id?",
        name: "saida",
        props: true,
        meta: { title: "Saída" },
        component: () => import("src/pages/Saidas/Saida.vue"),
      },
      {
        path: "/perfil",
        name: "perfil",
        meta: { title: "Meu perfil" },
        component: () => import("pages/Perfil/Index.vue"),
      },
      {
        path: "/importacao",
        name: "importacao",
        meta: { title: "Importação de produtos em lote" },
        component: () => import("pages/Produtos/Importacao.vue"),
      },
      {
        path: "/crediarios",
        name: "crediarios",
        meta: { title: "Crediários" },
        component: () => import("pages/Crediario/Index.vue"),
      },
      {
        path: "/crediario/:id?",
        name: "crediario",
        meta: { title: "Crediário" },
        props: true,
        component: () => import("pages/Crediario/Crediario.vue"),
      },
      {
        path: "/usuarios",
        name: "usuarios",
        meta: { title: "Usuários" },
        component: () => import("pages/Usuarios/Index.vue"),
      },
      {
        path: "/caixa",
        name: "Movimento do dia",
        meta: { title: "Caixa" },
        component: () => import("pages/Caixa/Index.vue"),
      },
    ],
  },
  {
    path: "/",
    children: [
      {
        path: "/recibo-venda",
        name: "recibo-venda",
        props: true,
        component: ReciboVenda,
      },
      {
        path: "/recibo-entrada",
        name: "recibo-entrada",
        props: true,
        component: ReciboEntrada,
      },
      {
        path: "/recibo-movimento",
        name: "recibo-movimento",
        props: true,
        component: ReciboMovimento,
      },
      {
        path: "/print-report",
        name: "print-report",
        props: true,
        component: PrintReport,
      },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/Error404.vue"),
  },
];

export default routes;
